import React, { useContext } from 'react'

import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import './Testimonial.css'
import { useState } from 'react'
import InstitutionContext from '../../Context/InstitutionContext'

const Testimonial = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData

  let testiData = InstitutionData.Testimonial

  testiData = testiData.map((val, i) => {
    return { name: val.name, description: val.description, src: val.img }
  })

  const [testimonials, setTestimonials] = useState(testiData)

  const styles = ['ecllip4', 'ecllip3', 'ecllip2']

  const leftClicked = () => {
    setTestimonials((testi) => {
      const tempTesti = []
      const firstTesti = testi.pop()

      tempTesti.push(firstTesti)

      testi.map((ts, i) => {
        tempTesti.push(ts)
        return ts
      })

      return tempTesti
    })
  }

  const rightClicked = () => {
    setTestimonials((testi) => {
      const tempTesti = []
      const firstTesti = testi.slice(0, 1)

      testi.map((ts, i) => {
        if (i !== 0) {
          tempTesti.push(ts)
        }
        return ts
      })

      tempTesti.push(firstTesti[0])

      return tempTesti
    })
  }

  return (
    <div
      className={`sans-sarif  max500:h-[22rem] max700:h-[58rem] size h-[50rem]`}
    >
      <div
        className={`Test-size py-[0.2rem] flex flex-col item-center  bg-black`}
        style={{
              backgroundImage: `url(${InstitutionData.TestimonialBg})`,
                backgroundSize: 'cover'
            }}>
      >
        <h1
          className={` Test-text m-8 text-white-250 max478:text-white-[4rem] font-bold`}
        >
          TESTIMONIAL
        </h1>
        <div className={``}>
          <div className={``}>
            <ul className={`feedback `}>
              <div
                className={`absolute w-screen flex justify-center flex-col min-h-[25rem] `}
              ></div>
              {testimonials.map((test, i) => {
                return (
                  <li key={styles[i]}>
                    <img src={test.src} alt="" className={styles[i]} />
                  </li>
                )
              })}
              <BsArrowLeftCircle
                color="white"
                size={'2rem'}
                className={`absolute left-16 cursor-pointer max536:left-6 max500:left-2 max406:h-[1.5rem]`}
                onClick={leftClicked}
              />
              <BsArrowRightCircle
                color="white"
                size={'2rem'}
                className={`absolute right-16  cursor-pointer max536:right-6 max500:right-2 max406:h-[1.5rem]`}
                onClick={rightClicked}
              />
            </ul>
          </div>
          <h1
            className={`mona h-[4.5rem] w-[100%] font-[500] `}
            style={{ fontSize: '1.8rem' }}
          >
            {testimonials[1].name}
          </h1>
          <div
            className={`flex relative z-2 object-cover  justify-center max1050:pl-8 max1050:pr-8`}
          >
            <h2
              className={`text-[1rem] z-2 pt-4  w-[50rem] max478:text-[0.75rem] text-center font-sans text-white`}
              style={{
                letterSpacing: '1.6px'
              }}
            >
              <span className={` text-[1.4rem]`}>"</span>
              {testimonials[1].description}
              <span className={`text-[1.4rem]`}>"</span>
            </h2>
          </div>

          <div className={`flex justify-center item-center mt-1`}>
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/Assests/yellow star.png`}
              className={`h-[1.8rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] max406:h-[1rem]`}
              alt=""
            />
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/Assests/yellow star.png`}
              className={`h-[1.8rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] `}
              alt=""
            />
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/Assests/yellow star.png`}
              className={`h-[1.8rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] `}
              alt=""
            />
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/Assests/yellow star.png`}
              className={`h-[1.8rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] `}
              alt=""
            />
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/Assests/yellow star.png`}
              className={`h-[1.8rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem]  `}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
