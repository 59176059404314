import React from 'react'
import video from "../../Utils/images/video.mp4"

const Header = () => {

  return (
    <div className={` flex items-center justify-center h-[30rem] pb-20`}>
      <div className={`-z-10 `}>
        <video
          autoPlay
          loop
          muted
          playsInline={true}
          controls={false}
          className={`object-cover object-top h-[38rem] w-screen max-w-screen max600:h-[35rem]`}
        >
          <source
            src={video}
            type="video/mp4"
          />
        </video>
        <h5 className="  w-full flex items-center justify-center bg-black shrink-0 text-[1rem]">
          <p className="Fussion1 text-center justify-center items-center text-[1.2rem] max375:text-[1rem] text-white">
            Ignite Your Dancing Flame
          </p>
        </h5>
      </div>
    </div>
  )
}

export default Header
