import React, { useContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import './userprofile.css'
import Context from '../../Context/Context'
import { useNavigate } from 'react-router-dom'
import Button2 from '../Utils/Buttons/Button2'
import InstitutionContext from '../../Context/InstitutionContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import CloseIcon from '@material-ui/icons/Close';

const UserProfile = ({
  isUserAdd,
  updateUserInList,
  isOpen,
  onClose,
  user
}) => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [pdate, setPDate] = useState('')
  const [status, setStatus] = useState('')
  const [pstatus, setPStatus] = useState('')
  const [productDetails, setProductDetails] = useState([]);
  const [productType, setProductType] = useState('')
  const [balance, setBalance] = useState('')
  const [country, setCountry] = useState(
    user && user.country ? user.country : ''
  )
  const UtilCtx = useContext(Context).util
  const Ctx = useContext(Context)
  const [cognitoId, setCognitoId] = useState('')
  const Navigate = useNavigate()
  const [lastMonthZPoints, setLastMonthZPoints] = useState('')
  const [currentMonthZPoints, setCurrentMonthZPoints] = useState('')
  const [selectedProductAmount, setSelectedProductAmount] = useState('');
  const formatDate = (epochDate) => {
    const date = new Date(epochDate)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  useEffect(() => {
    setName(user && (user.userName ? user.userName : ''))
    setEmail(user && (user.emailId ? user.emailId : ''))
    setPhoneNumber(user && (user.phoneNumber ? user.phoneNumber : ''))
    setStatus(user && (user.status ? user.status : ''))
    setProductType(user && (user.productType ? user.productType : ''))
    setBalance(user && (user.balance ? user.balance : ''))
    setCognitoId(user && (user.cognitoId ? user.cognitoId : ''))
    setCountry(user && (user.country ? user.country : ''))
    setLastMonthZPoints(
      user && (user.lastMonthZPoints ? user.lastMonthZPoints : '0')
    )
    setCurrentMonthZPoints(
      user && (user.currentMonthZPoints ? user.currentMonthZPoints : '0')
    )
  }, [user])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productList = await API.get(
          'user',
          `/any/products/${InstitutionData.InstitutionId}`
        );
        setProductDetails(productList);
      } catch (error) {
        console.error('Error fetching product details:', error);
        UtilCtx.setLoader(false);
      }
    };

    fetchProducts();
  }, [user, InstitutionData.InstitutionId, UtilCtx]);

  const handleProductTypeChange = (e) => {
    const selectedProductType = e.target.value;
    // Update the productType state
    setProductType(selectedProductType);
    // Find the corresponding product in the productDetails array
    const selectedProduct = productDetails.find(product => product.subscriptionType === selectedProductType);
    // Update the selected product amount
    if (selectedProduct) {
      setSelectedProductAmount(selectedProduct.amount);
    } else {
      setSelectedProductAmount('');
    }
  };
  const onUpdateUser = async (e) => {
    e.preventDefault()

    if (
      !(
        name &&
        email &&
        phoneNumber &&
        status &&
        balance &&
        country &&
        cognitoId
      )
    ) {
      alert('Fill all Fields')
      return
    }
    if (!name) {
      alert('Fill Name')
      return
    } else if (!email) {
      alert('Fill email')
      return
    } else if (!phoneNumber) {
      alert('Fill Phone Number')
      return
    } else if (!status) {
      alert('Fill Status')
      return
    } else if (!balance) {
      alert('Fill Balance')

      return
    }

    UtilCtx.setLoader(true)

    try {
      await API.put(
        'user',
        `/admin/update-user/${InstitutionData.InstitutionId}`,
        {
          body: {
            cognitoId: cognitoId,
            emailId: email,
            userName: name,
            phoneNumber: phoneNumber,
            status: status,
            productType: productType,
            balance: balance,
            country: country,
            currentMonthZPoints: currentMonthZPoints,
            lastMonthZPoints: lastMonthZPoints
          }
        }
      )

      await API.post('user', `/admin/user-payment-update/${InstitutionData.InstitutionId}`, {
        body: {
          cognitoId: cognitoId,
          status: status,
          institution: InstitutionData.InstitutionId,
          productType: productType,
          amount: selectedProductAmount,
          paymentStatus: pstatus,
          paymentDate: pdate,
          emailId: email
        }
      })

      alert('User Updated')

      UtilCtx.setLoader(false)

      const userList = Ctx.userList.map((item) => {
        if (item.cognitoId === cognitoId) {
          item.emailId = email
          item.userName = name
          item.phoneNumber = phoneNumber
          item.status = status
          item.productType = productType
          item.balance = balance
          item.currentMonthZPoints = currentMonthZPoints
          item.lastMonthZPoints = lastMonthZPoints
          item.country = country
          return item
        } else {
          return item
        }
      })

      Ctx.setUserList(userList)

      onClose()
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
  }

  useEffect(() => {
    if (Ctx.isUserDataLoaded) {
      if (Ctx.userData.userType !== 'admin') {
        Navigate('/')
      }
    }
  }, [Ctx, Navigate])

  const getWhatsappUrl = () => {
    const currentMonth = new Date().toLocaleString('default', {
      month: 'long'
    })
    const message = `Payment screenshot for ${currentMonth}`

    const whatsappUrl = `https://wa.me/${InstitutionData.WpNo}?text=${encodeURIComponent(message)}`
    return whatsappUrl
  }

  const sendReminder = async (cognitoId) => {
    UtilCtx.setLoader(true)

    const pa = InstitutionData.UpiId
    // const am = 10;

    try {
      const res = await API.post(
        'user',
        `/user/send-email/${InstitutionData.InstitutionId}`,
        {
          body: {
            pa,
            paymentPhoneNumber: InstitutionData.WpNo,
            whatsappUrl: getWhatsappUrl(),
            qrLink: `https://institution-qr-code.s3.amazonaws.com/${InstitutionData.InstitutionId}/QRCode.jpg`,
            cognitoId
          }
        }
      )

      alert(res.message)
      UtilCtx.setLoader(false)
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
  }

  if (!isOpen) return null

  return (
    <div className={`modal`}>
      <div className={`modal-content flex items-center justify-center mt-[10rem]`}>
        <div className={``}>
          <div
            className={`w-[100%] max1050:w-[100%] max-w-[36rem] rounded-3xl p-2 flex flex-col max536:w-[95%] bg-[#ffff]`}
          >
            <form className={`flex flex-col gap-6 max560:w-full`}>
              <CloseIcon onClick={onClose} className='crossme' />
              {/* Name */}
              <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                <div className="flex flex-col gap-1 justify-center">
                  <label className="ml-2">Name</label>
                  <input
                    required
                    placeholder="Name"
                    className="bg-inputBgColor  px-4 py-2 rounded-lg"
                    type={'text'}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                  />
                </div>

                <div className="flex flex-col gap-1 justify-center">
                  <label className="ml-2">Email</label>
                  <input
                    className={` bg-inputBgColor px-4 py-2 rounded-lg`}
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                <div className="flex flex-col gap-1 justify-center">
                  <label className="ml-2">Country</label>
                  <input
                    className="bg-inputBgColor px-4 py-2 rounded-lg"
                    type="text"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value)
                    }}
                  />
                </div>

                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Phone Number</label>
                  <input
                    className={` bg-inputBgColor px-4 py-2 rounded-lg`}
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value)
                    }}
                  />
                </div>
              </div>

              {/* Attendance and Joining Date */}
              <div
                className={`flex flex-row justify-between gap-4 max560:flex-col max560:gap-8`}
              >
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Attendance</label>
                  <div className={`flex items-center justify-center w-[50%]`}>
                    <input
                      className={` bg-inputBgColor px-2 py-2 rounded-lg`}
                      type="number"
                      value={currentMonthZPoints}
                      onChange={(e) => {
                        setCurrentMonthZPoints(e.target.value)
                      }}
                    />
                    <p className={`mt-3 mx-2`}>/</p>
                    <input
                      className={` bg-inputBgColor px-2 py-2 rounded-lg `}
                      type="number"
                      value={lastMonthZPoints}
                      onChange={(e) => {
                        setLastMonthZPoints(e.target.value)
                      }}
                    />
                  </div>
                </div>

                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={``}>Joining Date</label>
                  <div
                    className={` bg-inputBgColor px-4 py-2 rounded-md`}
                    style={{
                      width: '270px'
                    }}
                  >
                    {formatDate(user.joiningDate)}
                  </div>
                </div>
              </div>

              {/* User Status and Due */}
              <div
                className={`flex flex-row justify-between gap-4 max560:flex-col max560:gap-8`}
              >
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>User Status</label>
                  <select
                    className={` bg-inputBgColor px-4 py-2 rounded-lg `}
                    style={{
                      width: '222px'
                    }}
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>

                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Due</label>
                  <input
                    className={` bg-inputBgColor px-4 py-2 rounded-lg`}
                    type="text"
                    value={balance}
                    onChange={(e) => {
                      setBalance(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div
                className={`flex flex-row justify-between gap-4 max560:flex-col max560:gap-8`}
              >
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Product Type</label>
                  <select
                    className={` bg-inputBgColor px-4 py-2 rounded-lg `}
                    style={{
                      width: '222px'
                    }}
                    value={productType}
                    onChange={handleProductTypeChange}
                  >
                    <option value="">Select Product Type</option>
                    {productDetails.map((product) => (
                      <option key={product.subscriptionType} value={product.subscriptionType}>
                        {product.subscriptionType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Amount</label>
                  <input
                    className={` bg-inputBgColor px-4 py-2 rounded-lg`}
                    type="text"
                    value={selectedProductAmount}
                    readOnly // Make the input read-only
                  />
                </div>
              </div>

              <div
                className={`flex flex-row justify-between gap-4 max560:flex-col max560:gap-8`}
              >
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Payment Status</label>
                  <select
                    className={` bg-inputBgColor px-4 py-2 rounded-lg `}
                    style={{
                      width: '222px'
                    }}
                    value={pstatus}
                    onChange={(e) => {
                      setPStatus(e.target.value)
                    }}
                  >
                    <option value="">Select Payment Status</option>
                    <option value="Unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Payment Date</label>
                  <input class="bg-inputBgColor px-4 py-2 rounded-lg" placeholder="Date" type="datetime-local" value={pdate}
                    onChange={(e) => {
                      setPDate(e.target.value)
                    }} />
                </div>
              </div>

              <div className={`flex flex-row items-center mt-3 newedit`}>
                {/* Update Profile Button */}
                <Button2
                  data={
                    <>
                      <FontAwesomeIcon icon={faUserEdit} className="mr-2" />{' '}
                      Update Profile
                    </>
                  }
                  fn={(e) => {
                    onUpdateUser(e)
                    onClose()
                  }}
                  w="12rem"
                />
                {/* Send Invoice Button */}
                <button
                  className="bg-white rounded-lg text-black py-2 mb-1 flex items-center invbut"
                  onClick={(e) => {
                    e.preventDefault()
                    sendReminder(cognitoId)
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
                  Send Invoice
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
