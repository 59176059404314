import React, { useContext } from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Home/Footer'
import InstitutionContext from '../Context/InstitutionContext'

const PrivacyPolicy = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData

  return (
    <div className={`text-justify flex flex-col w-full`}>
      <NavBar />
      <div className={`flex flex-col m-[4rem] w-[90vw] gap-10 max-w-[80rem]`}>
        <h4 className={'text-[2rem] max450:text-[1rem] text-center mt-8 font-bold w-full sm:ml-0 ml-5 '}>Privacy Policy</h4>
        {InstitutionData.PrivacyPolicy.split('\n\n').map((para, index) =>
          para.split('90')[0] === '' ? (
            <>
              {para.split('90')[1]}
              <br />
              <br />
            </>
          ) : (
            <>
              {para.split('99')[0] === '' ? (
                <h2 className="p-10 ">{para.split('99')[1]}</h2>
              ) : (
                <li>{para}</li>
              )}
            </>
          )
        )}
      </div>
      <div className={`mt-4`}>
        <Footer />
      </div>
    </div>
  )
}

export default PrivacyPolicy