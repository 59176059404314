import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import Context from '../../Context/Context'
import HappyprancerPaypalMonthly from '../Subscription/HappyprancerPaypalMonthly'
import HappyprancerPaypalHybrid from '../Subscription/HappyprancerPaypalHybrid'
import InstitutionContext from '../../Context/InstitutionContext'

export default function Subscription() {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const Ctx = useContext(Context)
  const UserCtx = useContext(Context).userData

  const Navigate = useNavigate()
  return (
    <>
      <div
        className={`Back text-[1.5rem] flex flex-col items-center h-auto max980:h-[auto] justify-center gap-[5rem] pb-20`}
        style={{
          backgroundImage: `url(${InstitutionData.SubscriptionBg})`,
          backgroundSize: 'cover'
        }}
      >
        <div className={`text-center mt-20 sans-sarif `}>
          <h1 className={`font-bold `}  style={{color: InstitutionData.SubscriptionBg ? "white" : "black"}}>Monthly Membership Subscription </h1>
          <h3 className={`text-[1rem] font-[600]`}  style={{color: InstitutionData.SubscriptionBg ? "white" : "black"}}>
            see what are the pricing in details
          </h3>
        </div>
        <ul
          className={`flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 max600:mr-7`}
        >
          {Ctx.productList.map((item, i) => {
            return (
              <li
                key={item.productId + `home${i}`}
                className={` w-[24rem] h-auto p-10 rounded-[2rem] z-0  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-[0.1rem] bg-white `}
                style={{
                  borderColor: InstitutionData.LightPrimaryColor
                }}
              >
                <p className={`text-[1.6rem] font-bold`}>{item.heading}</p>
                <ul className={` text-[1rem] h-auto pl-0 flex flex-col`}>
                  {item.provides.map((item, i) => {
                    return (
                      <li key={`${i}ok`}>
                        <p>{item}</p>
                      </li>
                    )
                  })}
                </ul>
                <h1 className={`text-left w-[100%]`}>
                  {(item.currency === 'INR' ? '₹ ' : '$ ') +
                    parseInt(item.amount) / 100 +
                    '/' +
                    item.durationText}
                </h1>
                {Ctx.isAuth ? (
                  <div className="z-1">
                    {UserCtx.status === 'Active' ? (
                      <p
                        className={`text-[1rem] w-[15rem] px-12 py-2 rounded-2xl bg-white border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]`}
                        style={{
                          color: InstitutionData.LightPrimaryColor,
                          borderColor: InstitutionData.LightPrimaryColor
                        }}
                      >
                        Already Subscribed
                      </p>
                    ) : (
                      <>
                        {item.subscriptionType === 'Hybrid' &&
                        item.currency === 'USD' ? (
                          <HappyprancerPaypalHybrid />
                        ) : item.currency === 'INR' ? (
                          item.durationText === 'Month' && (
                            <a
                              className={`w-[15rem] text-white px-12 py-2 rounded-2xl hover:text-black hover:bg-primaryColor hover:border-primaryColor hover:border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw] cursor-pointer`}
                              style={{
                                backgroundColor:
                                  InstitutionData.LightPrimaryColor
                              }}
                              href={`http://localhost:3000/pay/${InstitutionData.InstitutionId}/${item.productId}/${UserCtx.cognitoId}`}
                            >
                              Subscribe
                            </a>
                            // <RazorpayPayment productId={item.productId} />
                          )
                        ) : (
                          item.durationText === 'Month' && (
                            <HappyprancerPaypalMonthly />
                          )
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      Navigate('/signup')
                    }}
                    className={`w-[15rem] text-white px-12 py-2 rounded-2xl hover:text-lightPrimaryColor hover:bg-white hover:border-lightPrimaryColor hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]`}
                    style={{
                      backgroundColor: InstitutionData.LightPrimaryColor
                    }}
                  >
                    Sign Up
                  </button>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
