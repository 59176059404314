import React, { useContext, useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify'
import NavBar from '../Components/NavBar'
import Context from '../Context/Context'
import { useNavigate } from 'react-router-dom'
import './Login.css'
import Country from '../Components/Auth/Country'
import InstitutionContext from '../Context/InstitutionContext'

const NewLogin = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('91')
  const [err, setErr] = useState('')
  const UtilCtx = useContext(Context).util
  const UserCtx = useContext(Context)
  const [OTP, setOTP] = useState('')
  const [signinResponse, setSigninResponse] = useState(null)
  const [otpTimer, setOtpTimer] = useState(null)

  const Navigate = useNavigate()

  useEffect(() => {
    let timerInterval = null;

    if (otpTimer === 0) {
      clearInterval(timerInterval); // Stop the timer when it reaches 0
    } else {
      timerInterval = setInterval(() => {
        setOtpTimer(prevTimer => prevTimer - 1); // Decrement the timer every second
      }, 1000);
    }
    return () => clearInterval(timerInterval); // Cleanup function to clear interval on component unmount
  }, [otpTimer]);

  const startTimer = () => {
    setOtpTimer(30); // Set the timer to 60 seconds
  }

  const sendOTP = async (event) => {
    event.preventDefault()
    UtilCtx.setLoader(true)

    try {
      const exist = await API.post(
        'user',
        `/any/phone-exists/${InstitutionData.InstitutionId}`,
        {
          body: {
            phoneNumber: `+${countryCode}${phoneNumber}`
          }
        }
      )
      if (exist) {
        const response = await Auth.signIn(`+${countryCode}${phoneNumber}`)
        setSigninResponse(response)
        startTimer() // Start the timer when OTP is sent
      } else {
        alert('Sign Up First')
        Navigate('/signup')
      }
    } catch (e) {
      setErr(e.message)
    } finally {
      UtilCtx.setLoader(false)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    UtilCtx.setLoader(true)

    try {
      const user = await Auth.sendCustomChallengeAnswer(signinResponse, OTP)
      console.log(await Auth.currentSession())
      if (user) {
        const userdata = await API.get(
          'user',
          `/user/profile/${InstitutionData.InstitutionId}`
        )
        UserCtx.setUserData(userdata)
        UserCtx.setIsAuth(true)
        UtilCtx.setLoader(false)
        alert('Logged In')
        UserCtx.onAuthLoad(true, InstitutionData.InstitutionId)
        Navigate('/dashboard')
      } else { 
        setErr(`Incorrect +${countryCode}${phoneNumber}`)
        UtilCtx.setLoader(false)
      }
    } catch (e) {
      setErr(e.message)
    } finally {
      UtilCtx.setLoader(false)
    }
  }

  return (
    <div className={`w-screen min-h-screen`}>
      <NavBar />
      <div className={`flex flex-col items-center mt-12 text-black`}>
        {/* <h3 className={`text-[2rem]`}>FITNESS</h3>
        <div className={`w-[80%] h-[0.08rem] bg-black flex`}></div> */}
        <div className={`flex w-[100%] gap-16 justify-center items-end mt-28`}>
          <form
            className={`w-[50vw] max800:w-[90vw]  max-w-[28rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
          >
            <h3 className={`text-[1.2rem] font-[700]`}>Login</h3>
            <p className={`text-center font-[500] text-[0.8rem] mb-[0rem]`}>
              Hey, Enter your details to sign in <br /> to your account
            </p>
            <ul className={`flex flex-col items-center`}>
              <select
                name="countryCode"
                id=""
                value={countryCode}
                className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 rounded-2 max500:w-[80vw] mt-6`}
                onChange={(e) => {
                  setCountryCode(e.target.value.toString())
                }}
              >
                {<Country />}
              </select>
              <li
                className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}
              >
                <div className={`relative`}>
                  <input
                    className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                    onC
                    type="text"
                    placeholder="Enter Phone"
                    value={phoneNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value
                      if (/^\d+$/.test(inputValue)) {
                        if (inputValue.length >= 0 && inputValue.length <= 10) {
                          setPhoneNumber(inputValue)
                        }
                      }
                    }}
                  />
                </div>
              </li>
              <li
                className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}
              >
                <div className={`relative`}>
                  <input
                    className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                    onC
                    type="text"
                    placeholder="OTP"
                    value={OTP}
                    onChange={(e) => setOTP(e.target.value)}
                    disabled={!signinResponse}
                  />
                </div>
              </li>
            </ul>
            {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}
            <button
              className={`w-[19.5rem] py-2  mb-3 rounded-2 max500:w-[80vw]`}
              style={{
                backgroundColor: signinResponse
                  ? '#00000000'
                  : InstitutionData.LightPrimaryColor,
                color: signinResponse
                  ? InstitutionData.LightPrimaryColor
                  : '#ffffff',
                opacity: phoneNumber ? 1 : 0.5
              }}
              onClick={sendOTP}
              disabled={phoneNumber.length !== 10 || (otpTimer !== null && otpTimer > 0)}
              >
                {otpTimer !== null && otpTimer > 0 ? `Resend OTP in ${otpTimer} seconds` : 'Send OTP'}
            </button>
            <button
              className={`w-[19.5rem] py-2 mt-3 mb-3 text-white rounded-2 max500:w-[80vw]`}
              style={{
                backgroundColor: InstitutionData.LightPrimaryColor,
                opacity: OTP ? 1 : 0.5
              }}
              onClick={onSubmit}
              disabled={!OTP}
              hidden={!signinResponse}
            >
              Sign in
            </button>
            <p
              className={` text-[0.85rem] text-black cursor-pointer`}
              onClick={() => {
                Navigate('/signup')
              }}
            >
              Don’t have an account?{' '}
              <span
                className={`font-[500]`}
                style={{
                  color: InstitutionData.LightPrimaryColor
                }}
              >
                Register Now
              </span>{' '}
            </p>
          </form>
          <img
            src={`https://institution-utils.s3.amazonaws.com/institution-common/images/Auth/danceAuth.png`}
            alt="Dance"
            className={`w-[20%] max800:hidden`}
          />
        </div>
      </div>
    </div>
  )
}

export default NewLogin
