import React, { useContext } from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Home/Footer'
import InstitutionContext from '../Context/InstitutionContext'

const AboutUs = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData

  return (
    <>
      <div className={`flex flex-col items-center `}>
        <NavBar />
        <div
          className={`flex flex-col items-center w-100 px-[2rem] mt-[2rem] overflow-x-hidden w-[90vw] max-w-[80rem]`}
        >
          <h1 className={`sans-sarif text-[4rem] text-center mt-9`}>
            About Us
          </h1>
          {InstitutionData?.AboutUs.split('\n\n').map((item) => {
            return <p className={`mt-8`}>{item}</p>
          })}
          {/* <p className={`mt-5`}>{InstitutionData?.src_pages_AboutUs__Text_2}</p>
          <p className={`mt-5`}>{InstitutionData?.src_pages_AboutUs__Text_3}</p>
          <p className={`mt-6 mb-20`}>
            {InstitutionData.src_pages_AboutUs__Text_4}
          </p> */}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
