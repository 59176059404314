import { API } from 'aws-amplify'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import InstitutionContext from '../Context/InstitutionContext'

const Pay = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const { institutionId, productId, cognitoId } = useParams()
  const razorpay = useRef()
  const [userData, setUserData] = useState({})
  const [productData, setProductData] = useState({
    provides: []
  })
  const [payLoading, setPayLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log(window.razorpay)
  }, [])

  useEffect(() => {
    const onLoad = async () => {
      try {
        const data = await API.put(
          'user',
          `/user/billing/subscription/user-and-product`,
          {
            body: { productId, institutionId, cognitoId }
          }
        )

        console.log(data)
        setUserData(data.userData)
        setProductData(data.productData)
      } catch (e) {
        console.error(e)
      } finally {
        setPayLoading(false)
      }
    }
    onLoad()
  }, [cognitoId, institutionId, productId])

  const handleSubscribe = async () => {
    setIsLoading(true)
    let response
    try {
      response = await API.put('user', `/user/billing/subscription`, {
        body: { productId, institutionId, cognitoId }
      })
      if (response.isError) {
        alert(response.error)
        setIsLoading(false)
        if (response.error === 'Subscription already active')
          window.history.back()
        return
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
    try {
      const options = {
        key:
          process.env.REACT_APP_STAGE === 'PROD'
            ? 'rzp_live_KBQhEinczOWwzs'
            : 'rzp_test_1nTmB013tmcWZS',
        subscription_id: response.paymentId,
        name: institutionId.toUpperCase(),
        description: response.subscriptionType,
        image: InstitutionData.logoUrl,
        handler: function (r) {
          console.log(r)
          const verify = async () => {
            setIsLoading(true)
            try {
              const res = await API.put(
                'user',
                `/user/billing/subscription/verify`,
                {
                  body: {
                    institutionId,
                    cognitoId,
                    subscriptionId: response.paymentId
                  }
                }
              )
              if (res.signatureIsValid) {
              } else {
                alert(
                  'Transaction Failed If your Amount was Deducted then Contact us'
                )
              }
              setIsLoading(false)
            } catch (e) {
              console.log(e)
              setIsLoading(false)
            } finally {
              window.history.back()
            }
          }
          verify()
        },
        prefill: {
          name: userData.userName,
          email: userData.emailId,
          contact: userData.phoneNumber
        },
        theme: {
          color: '#0000FF'
        }
      }
      const rzp1 = new window.Razorpay(options)
      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        setIsLoading(false)
      })
      const fields = rzp1.open()
      console.log(fields)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  if (payLoading) <></>
  else
    return (
      <div className="flex justify-center items-center h-screen flex-col gap-3">
        <div>
          <div
            className={`w-[24rem]  py-16 px-8 rounded-[2rem] z-10 flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-lightPrimaryColor border-[0.1rem] bg-white`}
          >
            <p className={`text-[1.6rem]`}>{productData.heading}</p>
            <ul className={` text-[1rem] h-auto pl-0 flex flex-col`}>
              {productData.provides.map((item) => {
                return (
                  <li>
                    <p>{item}</p>
                  </li>
                )
              })}
            </ul>
            <h1 className={`text-left w-[100%]`}>
              {(productData.currency === 'INR' ? '₹ ' : '$ ') +
                parseInt(productData.amount) / 100 +
                '/' +
                productData.durationText}
            </h1>
            {isLoading ? (
              <span className="animate-spin h-10 w-10 text-black border-[0.02rem] border-black rounded-full border-b-0 border-l-0"></span>
            ) : (
              <button
                ref={razorpay}
                className={`w-[15rem] text-white px-12 py-2 rounded-2xl bg-primaryColor h-[3rem] flex justify-center items-center max450:w-[60vw] cursor-pointer`}
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            )}
          </div>
        </div>
      </div>
    )
}

export default Pay
