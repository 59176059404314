import { useContext } from 'react'
import InstitutionContext from '../../Context/InstitutionContext'

const Choreography = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  return (
    <div className={`w-[100%] flex flex-col justify-center items-center pt-6`}>
      <iframe
        src={InstitutionData.YTLink}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="w-full max-w-screen-md h-[42vw] max-h-[47vw]" // Adjusted responsive styling
      ></iframe>
    </div>
  )
}

export default Choreography
